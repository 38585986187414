
/**
 * Setting view  for the system change modes
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { Component, Mixins } from "vue-property-decorator";
import { SystemSettings } from "@/services/SystemSettings";
import TableHeader from "@/components/tableHeader.vue";
import { ActionMethod, mapActions, mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "SettingView",
  components: {
    TableHeader
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapActions("Theme", {
      UPDATE_SYSTEM_STATE: "UPDATE_SYSTEM_STATE"
    })
  }
})
export default class SettingsView extends Mixins(confirmModal) {
  systemMode = true;
  readonly getUserAccess!: UserAccess;
  private UPDATE_SYSTEM_STATE!: ActionMethod;

  async created() {
    let maintenanceMode = await SystemSettings.getSystemConfig();
    if (maintenanceMode.status === 202) {
      this.systemMode = maintenanceMode.data.maintenanceMode;
    }
  }
  async toggleMaintenanceMode() {
    if (!this.getUserAccess.Access.EDIT.maintenanceMode.edit) return;

    this.confirm(
      (this as any).$t("maintenance.settings.confirmModalText"),
      async () => {
        let maintenanceMode = await SystemSettings.ToggleMaintenanceMode({
          maintenanceMode: this.systemMode
        });

        if (maintenanceMode.status === 202) {
          this.systemMode = maintenanceMode.data.maintenanceMode;
          this.UPDATE_SYSTEM_STATE(maintenanceMode.data.maintenanceMode);
        }
      },
      () => {
        this.systemMode = !this.systemMode;
      }
    );
  }
}
